export interface ZigPalette {
  neutral900: string;
  neutral800: string;
  neutral750: string;
  neutral700: string;
  neutral600: string;
  neutral500: string;
  neutral400: string;
  neutral300: string;
  neutral200: string;
  neutral175: string;
  neutral150: string;
  neutral100: string;
  neutral000: string;
  secondary: string;
  highlighted: string;
  red: string;
  criticalRed: string;
  green: string;
  links: string;
  yellow: string;
  contrasting: string;
  paleBlue: string;
  lightGrey: string;

  backgrounds: Record<
    | 'body'
    | 'neutralBg'
    | 'buttonPrimary'
    | 'richTextEditor'
    | 'inviteTableHighlight'
    | 'zscoreGradientProfits'
    | 'zscoreGradientRisk'
    | 'zscoreGradientService'
    | 'zscoreGradientBalanced'
    | 'header'
    | 'tableHeader'
    | 'marketplaceCarousel'
    | 'toastError'
    | 'toastSuccess'
    | 'zscore'
    | 'zscoreProgressBar'
    | 'tableRow',
    string
  >;
  chart: Record<'red' | 'green', string> &
    Record<
      'greenMiniGradient' | 'greenGradient' | 'redMiniGradient' | 'redGradient',
      string[]
    >;
  boxShadows: Record<'header' | 'zigMenu', string>;
  imageColorOverride?: string;
}

export type ZigTheme = {
  fontFamily: string[];
  fontFamilyH1H6?: string[];
  palette: Omit<ZigPalette, 'backgrounds' | 'chart' | 'boxShadows'>;
  backgrounds: ZigPalette['backgrounds'];
  boxShadows: ZigPalette['boxShadows'];
  chart: ZigPalette['chart'];
  mode: 'dark' | 'light';
  imageColorOverride?: string;
};

// https://stackoverflow.com/a/61132308/2044039
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type ZigThemeOverrides = DeepPartial<ZigTheme>;

export enum Features {
  ServiceWorker = 'serviceWorker',
  // this is a UI-only feature, it does not come from the backend
  NewPortfolioLayout = 'newPortfolioLayout',
  Referrals = 'referrals',
  AllowBuyCrypto = 'allowBuyCrypto',
  MinInvestment = 'minInvestment',
  CreateService = 'createServices',
  NewSignup = 'newSignup',
  EmailVerification = 'emailVerification',
  LoginOnlyAccess = 'loginOnly',
  NoIndex = 'noIndex',
  EnableTracking = 'enableTracking',
  Subscriptions = 'subscriptions',
  Kyc = 'kyc',
  ZScore = 'zscore',
  UsdtBscOnly = 'usdtBscOnly',
}

export type Mandatory2FA =
  | 'createServiceApiKey'
  | 'deleteServiceApiKey'
  | 'editServiceApiKey'
  | 'exchangeWithdraw'
  | 'getExchangeDepositAddress'
  | 'investInService'
  | 'investOutAllService'
  | 'investOutService';

interface TranslationOverrides {
  [x: string]:
    | string
    | string[]
    | TranslationOverrides
    | TranslationOverrides[];
}

export type WhitelabelOverride = {
  name: string;
  title: string;
  baseApi: string;
  baseReferralApi: string;
  domain: string;
  description?: string;
  marketplaceCards?: number;
  locales?: string[];
  translationOverrides?: TranslationOverrides;
  minInvestment?: Partial<
    Record<'USDT' | 'ETH' | 'BTC' | 'USDC' | 'BNB', number>
  >;
  featureOverrides?: Partial<Record<Features, boolean>>;
  slug?: string;
  logo?: string;
  links?: {
    tos?: string;
    helpUrl?: string;
    mainAppLink?: string;
    privacyPolicy?: string;
    subscriptionPurchaseLink?: string;
  };
  background?: string;
  backgroundImage?: string | null;
  baseTheme?: string;
  marketplaceMinScore?: number;
  themeOverrides?: ZigThemeOverrides;
  tools?: {
    twitter_tracker?: string;
    intercom?: string;
    google_tag_manager?: string;
  };
  social: Partial<
    Record<
      | 'telegram'
      | 'twitter'
      | 'discord'
      | 'medium'
      | 'linkedin'
      | 'instagram'
      | 'youtube',
      string | void
    >
  >;
  zignalySuccessFee?: number;
  isMaintenance?: boolean;
};

export type WhitelabelBackendConfig = Pick<
  WhitelabelOverride,
  | 'minInvestment'
  | 'social'
  | 'domain'
  | 'tools'
  | 'slug'
  | 'zignalySuccessFee'
  | 'title'
  | 'description'
> & {
  name: string;
  type: 'lite' | 'heavy'; // this could be wrong but let the future me figure out what is the other type
  settingFee: number;
  monthlyFee: number;
  image: string;
  pwaLogo: string;
  pwaLogoMac: string;
  logo: string;
  favicon: string;
  supportUrl: string;
  supportHelpCenter: string;
  languages: WhitelabelOverride['locales'];
  settings: WhitelabelOverride['featureOverrides'] & {
    // not used ever
    translationOw: boolean;
    // why we have a numeric value among booleans? who knows...
    marketplaceCards: number;
  };
  mainAppLink?: string;
  tos?: string;
  theme: string;
  themeOverride: {
    background: string;
    backgroundImage: string;
    themeOverrides: ZigThemeOverrides;
  };
  zScoreName?: string;
  zScoreIcon?: string;
  privacyPolicy?: string;
  subscriptionPurchaseLink?: string;
  marketplaceMinScore: number;
  emailOrigin: string;
  replyTo: string;
  mandatory2FA: [Mandatory2FA];
};
