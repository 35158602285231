import React, { useRef, useState } from "react";
import { Box, CircularProgress, IconButton, SxProps, useTheme } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import { LogoContainer } from "./styles";
import { ErrorMessage, ZigTypography } from "../../../index";
import ZigButton from "../ZigButton";
import ZigAlertMessage from "../../display/ZigAlertMessage";
import { useTranslation } from "react-i18next";

export type ZigImageInputProps<T extends string | File> = {
  label?: string | JSX.Element;
  buttonLabel?: string | JSX.Element;
  description?: string | JSX.Element;
  info?: string | JSX.Element;
  buttonTooltip?: string;
  disabled?: boolean;
  size?: number;
  error?: string;
  uploadFn?: (file: File) => Promise<T>;
  sx?: SxProps;
  onChange: (image: T | null) => void;
  id?: string;
  value: T;
  renderer?: (image: T) => JSX.Element;
};

const ZigImageInput = React.forwardRef(
  <T extends string | File>(
    {
      label,
      buttonLabel,
      description,
      value,
      info,
      size,
      onChange,
      renderer,
      disabled,
      buttonTooltip,
      error,
      id,
      sx,
      uploadFn,
    }: ZigImageInputProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation("zignaly-ui", { keyPrefix: "ZigImageInput" });
    const theme = useTheme();
    const [uploading, setUploading] = useState(false);
    const fileInput = useRef<HTMLInputElement>();
    async function uploadLogo(e: React.ChangeEvent<HTMLInputElement>) {
      const file = e.target.files?.[0];
      if (!file) return;
      setUploading(true);
      try {
        onChange(uploadFn ? await uploadFn(file) : (file as T));
      } finally {
        setUploading(false);
      }
    }

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        gap={1}
        sx={sx}
        id={id}
        ref={ref}
      >
        <Box>
          {!!label &&
            (typeof label === "string" ? (
              <ZigTypography component="p" variant={"body1"}>
                {label}
              </ZigTypography>
            ) : (
              label
            ))}

          {!!description &&
            (typeof description === "string" ? (
              <ZigTypography component="p" variant={"body2"}>
                {description}
              </ZigTypography>
            ) : (
              description
            ))}
        </Box>

        {!!value && (
          <LogoContainer>
            <Box sx={{ opacity: uploading ? 0.3 : 1 }}>
              {renderer ? (
                renderer(value)
              ) : (
                <img
                  width={size}
                  height={size}
                  src={typeof value === "string" ? value : URL.createObjectURL(value)}
                  alt={""}
                />
              )}
            </Box>

            {uploading ? (
              <CircularProgress size={24} />
            ) : (
              value && (
                <IconButton
                  sx={{
                    transition: "background .25s",
                    background: theme.palette.neutral800,
                    "&:hover": {
                      background: theme.palette.neutral700,
                    },
                  }}
                  onClick={() => {
                    onChange((typeof value === "string" ? "" : null) as T);
                    fileInput.current!.value = "";
                  }}
                  id={id && `${id}-clear`}
                >
                  <Close />
                </IconButton>
              )
            )}
          </LogoContainer>
        )}

        <Box>
          <ZigButton
            disabled={disabled}
            startIcon={<Edit sx={{ width: "12px", height: "12px" }} />}
            variant="text"
            // @ts-ignore
            component="label"
            tooltip={buttonTooltip}
            id={id && `${id}-edit`}
          >
            {buttonLabel || t("image-input-" + (value ? "edit" : "add"))}
            <input
              hidden
              ref={fileInput}
              type="file"
              accept="image/jpg, image/jpeg, image/gif, image/png, image/svg"
              onChange={uploadLogo}
            />
          </ZigButton>

          {error ? (
            <Box alignSelf="flex-start">
              <ErrorMessage text={error} id={id && `${id}-error`} />
            </Box>
          ) : (
            !!info &&
            (typeof info === "string" ? <ZigAlertMessage variant={"body2"} text={info} /> : info)
          )}
        </Box>
      </Box>
    );
  },
);

export default ZigImageInput;
