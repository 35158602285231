import React from "react";
import { GlobalStyles } from "@mui/material";
import { NiceScrollbar, ToasterStyle } from "../utils/css";

const NiceScrollGlobalStyle = <GlobalStyles styles={NiceScrollbar} />;
const ToasterGlobalStyle = <GlobalStyles styles={ToasterStyle} />;
const Reset = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        padding: 0,
        margin: 0,
        fontSize: "100%",
        overflow: "overlay",
        colorScheme: theme.palette.mode,
        fontFamily: theme.typography.fontFamily,
        background: theme.palette.backgrounds.body,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: theme.palette.neutral000,
        backgroundPositionX: "center",
        backgroundPositionY: "top",
      },
      a: {
        color: "inherit",
        textDecoration: "none",
      },
      "*": {
        boxSizing: "border-box",
      },
      ul: {
        padding: 0,
        margin: 0,
        listStyleType: "none",
      },
    })}
  />
);

export const GlobalAppStyle: React.FC = () => {
  return (
    <>
      {Reset}
      {ToasterGlobalStyle}
      {/* @ts-ignore */}
      {!!window.isSafari && NiceScrollGlobalStyle}
    </>
  );
};
