import {
  DepositStatuses,
  WithdrawalStatuses,
} from '../../apis/transfers/types';
import { TypographyProps } from '@mui/material';

export const withdrawalStatusColorMap: Record<
  WithdrawalStatuses,
  TypographyProps['color']
> = {
  [WithdrawalStatuses.STATUS_FAILED]: 'red',
  [WithdrawalStatuses.STATUS_COMPLETED]: 'green',
};

export const depositStatusColorMap: Record<
  DepositStatuses,
  TypographyProps['color']
> = {
  [DepositStatuses.STATUS_FAILED]: 'red',
  [DepositStatuses.STATUS_COMPLETED]: 'green',
};
