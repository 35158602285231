import React, { useEffect, useMemo } from 'react';
import {
  CoinLabel,
  ZigAlertMessage,
  ZigButton,
  ZigImageInput,
  ZigInput,
  ZigTypography,
} from '@zignaly-open/ui';
import { useTranslation } from 'react-i18next';
import { ConfigWrapper } from '../styled';
import { Grid, InputAdornment } from '@mui/material';
import { SectionHeader, SettingEnableSection } from '../atoms';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { WhitelabelBackendConfig } from '../../../apis/config/types';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SettingsConfigValidation } from '../validations';
import { useCurrentlyManagedWl, useSaveConfig } from '../use';
import { Features, getAllowedDeposits } from '@zignaly-open/ps2-definitions';
import { useWlConfigQuery } from '../../../apis/config/api';
import { MIN_USD_INVEST_AMOUNT_FIREBLOCKS } from '../constants';
import { ZScoreIcon } from '@ui/icons';
import { imageOrFile } from '../util';

const grayscaleIconStyle = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
};

const zigInputAmountLike = {
  '.MuiInputBase-root': {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  '.MuiInputBase-input': {
    fontSize: '20px !important',
    '&::placeholder': {
      fontSize: '16px !important',
    },
  },
};

export default function SettingsConfig() {
  const { t } = useTranslation('config');
  const wl = useCurrentlyManagedWl();
  const { data } = useWlConfigQuery(wl);
  const isFireblocks = (data as WhitelabelBackendConfig).settings[
    Features.UsdtBscOnly
  ];

  const defaultValues = useMemo(
    () => ({
      settings: {
        ...(data?.settings || {}),
        [Features.MinInvestment]:
          data?.settings?.[Features.MinInvestment] || isFireblocks,
      },
      zScoreName: (data?.zScoreName !== 'zscore' && data?.zScoreName) || '',
      zScoreIcon: data?.zScoreIcon || null,
      marketplaceMinScore: data?.marketplaceMinScore || 0,
      minInvestment: data?.minInvestment || {},
    }),
    [data],
  );

  const formMethods = useForm<Partial<WhitelabelBackendConfig>>({
    defaultValues,
    resolver: yupResolver(SettingsConfigValidation),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    watch,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = formMethods;

  const { submit, isLoading } = useSaveConfig(wl, (v) => {
    return {
      ...v,
      marketplaceMinScore: +v.marketplaceMinScore,
      settings: {
        ...v.settings,
        // Remove UsdtBscOnly as it's not allowed
        [Features.UsdtBscOnly]: undefined,
        marketplaceCards: +v.settings.marketplaceCards,
      },
    };
  });

  // field revalidation
  useEffect(() => {
    trigger('marketplaceMinScore');
  }, [watch(`settings.${Features.ZScore}`)]);

  const currenciesConfiguredForMin = useMemo(
    () => getAllowedDeposits(isFireblocks).spot,
    [isFireblocks],
  );
  useEffect(() => {
    currenciesConfiguredForMin.every((coin) =>
      trigger(`minInvestment.${coin}` as keyof WhitelabelBackendConfig),
    );
  }, [currenciesConfiguredForMin, watch(`settings.${Features.MinInvestment}`)]);

  return (
    <ConfigWrapper>
      <ZigTypography sx={{ mb: 2 }} variant={'h1'}>
        {t('navigation.settings-config')}
      </ZigTypography>
      <form onSubmit={handleSubmit(submit)}>
        <FormProvider {...formMethods}>
          <SettingEnableSection
            title={t('settings.login-only')}
            description={t('settings.login-only-description')}
            name={'settings.' + Features.LoginOnlyAccess}
            id={'settings-login-only-toggle'}
          />

          {/*Temporarily remove these ones*/}
          {/*<SettingEnableSection*/}
          {/*  title={t('settings.enable-tracking')}*/}
          {/*  description={t('settings.enable-tracking-description')}*/}
          {/*  name={'settings.' + Features.EnableTracking}*/}
          {/*  id={'settings-tracking-toggle'}*/}
          {/*/>*/}

          {/*<SettingEnableSection*/}
          {/*  title={t('settings.noindex')}*/}
          {/*  description={t('settings.noindex-description')}*/}
          {/*  name={'settings.' + Features.NoIndex}*/}
          {/*  id={'settings-noindex-toggle'}*/}
          {/*/>*/}

          <SettingEnableSection
            title={t('settings.zscore')}
            description={t('settings.zscore-description')}
            name={'settings.' + Features.ZScore}
            id={'settings-zscore-toggle'}
          />

          <Grid container spacing={2.5} sx={{ mt: -1.5, mb: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Controller
                name={'marketplaceMinScore'}
                control={control}
                render={({ field }) => (
                  <ZigInput
                    disabled={!watch('settings.zscore')}
                    id={'settings-min-zscore'}
                    placeholder={t('settings.zscore-placeholder')}
                    label={t('settings.min-zscore') + ':'}
                    type={'number'}
                    sx={zigInputAmountLike}
                    error={t(errors.marketplaceMinScore?.message)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position='end'
                          sx={
                            watch('settings.zscore') ? {} : grayscaleIconStyle
                          }
                        >
                          <ZScoreIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Controller
                name={'settings.marketplaceCards'}
                control={control}
                render={({ field }) => (
                  <ZigInput
                    disabled={!watch('settings.zscore')}
                    id={'settings-marketplace-cards'}
                    placeholder={t('settings.marketplace-cards')}
                    label={t('settings.marketplace-cards-description') + ':'}
                    type={'number'}
                    sx={zigInputAmountLike}
                    error={t(errors.settings?.marketplaceCards?.message)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ZigAlertMessage text={t('settings.do-not-leave-zscore-empty')} />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <Controller
                name={'zScoreName'}
                control={control}
                render={({ field }) => (
                  <ZigInput
                    disabled={!watch('settings.zscore')}
                    id={'settings-zscore-name'}
                    placeholder={'Z'}
                    label={t('settings.zscore-name') + ':'}
                    type={'text'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <ZigTypography variant={'body1'} color={'neutral400'}>
                            {t('settings.zscore-placeholder')
                              .slice(1)
                              .toLocaleLowerCase()}
                          </ZigTypography>
                        </InputAdornment>
                      ),
                    }}
                    error={t(errors.zScoreName?.message)}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}>
              <Controller
                name={'zScoreIcon'}
                control={control}
                render={({ field }) => (
                  <ZigImageInput
                    label={t('settings.zscore-icon') + ':'}
                    info={t('profile.optimal-size', { size: '64x64' })}
                    size={50}
                    error={t(errors.zScoreIcon?.message as string)}
                    renderer={(v) => (
                      <img
                        src={imageOrFile(v, '64x64')}
                        width={64}
                        height={64}
                        alt={''}
                      />
                    )}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2.5} sx={{ mt: -1.5, mb: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={3}></Grid>
          </Grid>

          {isFireblocks ? (
            <SectionHeader
              title={t('settings.min-investment')}
              sx={{ mb: 0.5, mt: 6 }}
            />
          ) : (
            <SettingEnableSection
              title={t('settings.min-investment')}
              description={t('settings.min-investment-description')}
              name={'settings.' + Features.MinInvestment}
              id={'settings-min-investment-toggle'}
            />
          )}

          <Grid container spacing={2.5} sx={{ mt: -1.5, mb: 3 }}>
            {currenciesConfiguredForMin.map((coin) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={`${coin}-min`}>
                <Controller
                  name={
                    `minInvestment.${coin}` as keyof WhitelabelBackendConfig
                  }
                  control={control}
                  render={({ field }) => (
                    <ZigInput
                      sx={zigInputAmountLike}
                      error={t(errors.minInvestment?.[coin]?.message, {
                        minFireblocksInvestAmount:
                          MIN_USD_INVEST_AMOUNT_FIREBLOCKS,
                      })}
                      id={'settings-min-investment-' + coin}
                      label={
                        t('settings.min-investment-amount', { coin }) + ':'
                      }
                      disabled={isLoading || !watch('settings.minInvestment')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            sx={{ color: (theme) => theme.palette.neutral100 }}
                          >
                            <Box
                              sx={
                                watch('settings.minInvestment')
                                  ? {}
                                  : grayscaleIconStyle
                              }
                            >
                              <CoinLabel coin={coin} name={''} size={24} />
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                      onBlur={(e) => {
                        if (e.target.value === '') field.onChange(0);
                        field?.onBlur?.();
                      }}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: 'right', mt: 10 }}>
            <ZigButton
              disabled={isLoading}
              size='large'
              variant='outlined'
              sx={{ mr: 2 }}
              onClick={() => reset(defaultValues)}
            >
              {t('cancel')}
            </ZigButton>
            <ZigButton
              disabled={isLoading}
              size='large'
              variant='contained'
              type={'submit'}
            >
              {t('save')}
            </ZigButton>
          </Box>
        </FormProvider>
      </form>
    </ConfigWrapper>
  );
}
