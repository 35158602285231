import React, { useMemo } from "react";
import { StyledSelectWrapper, ZigNormalSelectGlobalStyle } from "./styles";
import { ZigSelectOption, ZigSelectProps } from "./types";
import Select, { StylesConfig } from "react-select";
import { useTheme, Theme, Box, NativeSelect, useMediaQuery } from "@mui/material";
import ZigTypography from "../../display/ZigTypography";
import { ErrorMessage } from "../../display/ZigAlertMessage";
import { isCapacitor } from "../../../utils/capacitor";

const customStyles = (small: boolean, theme: Theme, userStyles: StylesConfig): StylesConfig => ({
  ...userStyles,
  menuPortal: (base) => ({
    ...base,
    zIndex: "1500 !important",
  }),
  menu: (base) => ({
    ...base,
    background: `${theme.palette.neutral800} !important`,
    border: `1px solid ${theme.palette.neutral600} !important`,
    color: `${theme.palette.neutral200} !important`,
    // hack for menu positioning on phone with keyboard open
    position: "static",
  }),
  option: (base, state) => ({
    ...base,
    ...(small
      ? {
          fontSize: "13px",
          lineHeight: "20px",
        }
      : {}),
    ":active": {
      // for mobile
      background: "rgba(255, 255, 255, 0.1)",
    },
    ...(state.isFocused
      ? {
          cursor: "pointer",
          background: "rgba(255, 255, 255, 0.1) !important",
        }
      : {}),
    ...(state.isSelected
      ? {
          color: theme.palette.neutral000,
          // TODO: this is absolute shit fix it
          background:
            theme.palette.mode === "light"
              ? theme.palette.paleBlue + "33"
              : theme.palette.contrasting + "33 !important",
        }
      : {}),
    ...userStyles.option?.(base, state),
  }),
  singleValue: (base, state) => ({
    ...base,
    display: state.selectProps.menuIsOpen ? "none" : "block",
    ...userStyles.singleValue?.(base, state),
  }),
});

function ZigSelect<T>({
  onChange,
  value,
  label,
  error,
  width,
  placeholder,
  forceNativeSelect = false,
  options,
  small = false,
  disabled,
  outlined,
  id,
  showBorder = true,
  hoverBackground = true,
  sx,
  styles: userStyles = {},
  ...props
}: ZigSelectProps<T>): JSX.Element {
  const muiTheme = useTheme();
  const styles = useMemo(
    () => customStyles(small, muiTheme, userStyles),
    [small, muiTheme, userStyles],
  );

  const sm = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const shouldUseNativeSelect = forceNativeSelect || isCapacitor || sm;

  return (
    // @ts-ignore
    <StyledSelectWrapper
      error={error}
      width={width}
      small={small}
      outlined={outlined}
      showBorder={showBorder}
      hoverBackground={hoverBackground}
    >
      {label && (
        <ZigTypography
          variant={small ? "body2" : "body1"}
          color={"neutral200"}
          id={id && `${id}-label`}
        >
          {label}
        </ZigTypography>
      )}
      <Box sx={sx}>
        {ZigNormalSelectGlobalStyle}
        {shouldUseNativeSelect ? (
          <NativeSelect
            disableUnderline={true}
            className={value ? " has-value" : ""}
            placeholder={placeholder || (typeof label === "string" ? label : "")}
            disabled={disabled}
            error={!!error}
            variant={"outlined"}
            id={id}
            sx={{
              ...(width ? { width } : {}),
              ...(outlined ? { "&, & select": { background: "transparent !important" } } : {}),
              ...(!showBorder
                ? {
                    "&, & select": {
                      border: "0px solid transparent",
                      background: "transparent !important",
                    },
                  }
                : {}),
              ...(small
                ? {
                    minHeight: "36px",
                  }
                : {}),
              'option[value=""][disabled]': {
                display: "none",
              },
              "select.MuiInput-input": {
                pl: small ? 2 : 3,
                ...(small
                  ? {
                      fontSize: "13px",
                    }
                  : {}),
                color: (theme) =>
                  `${value ? theme.palette.neutral100 : theme.palette.neutral400}${
                    !value ? "7f" : ""
                  } !important`,
                "-webkit-text-fill-color": (theme) =>
                  `${value ? theme.palette.neutral100 : theme.palette.neutral400}${
                    !value ? "7f" : ""
                  } !important`,
              },
            }}
            value={value}
            onChange={(e) => {
              const v = (
                typeof options?.[0]?.value === "number" ? +e.target.value : e.target.value
              ) as T;
              onChange?.(v, options?.find((x) => x.value === v) || null);
            }}
          >
            {!value && (
              <option value="" disabled selected hidden>
                {placeholder || (typeof label === "string" ? label : "")}
              </option>
            )}
            {options?.map((o) => (
              <option value={`${o.value}`} key={`${o.value}`} disabled={!!o.disabled}>
                {o.label}
              </option>
            ))}
          </NativeSelect>
        ) : (
          <Select
            id={id}
            styles={styles}
            components={{
              IndicatorSeparator: () => null,
            }}
            // if you want to use this inside of a modal, pass it `menuPosition="fixed"`, `menuShouldScrollIntoView={false}` and `menuShouldBlockScroll`
            isOptionDisabled={(option) => !!(option as ZigSelectOption<T>).disabled}
            options={options}
            isDisabled={disabled}
            onChange={(v) => {
              onChange?.((v as ZigSelectOption<T>)?.value, (v as ZigSelectOption<T>) || null);
            }}
            menuPortalTarget={document.body}
            placeholder={placeholder || label}
            value={options?.find?.((x) => x.value === value || (x as unknown) === value) || null}
            classNamePrefix="zig-react-select"
            instanceId={id}
            {...props}
          />
        )}
        {!!error && typeof error === "string" && (
          <Box mt="3px">
            <ErrorMessage text={error} id={id && `${id}-error-text`} />
          </Box>
        )}
      </Box>
    </StyledSelectWrapper>
  );
}

export default ZigSelect;
