import React, { useMemo, useRef, useState } from 'react';
import {
  createColumnHelper,
  PageContainer,
  ZigMenu,
  ZigInput,
  ZigSelect,
  ZigTable,
  ZigTypography,
  useZTypeWordConfirm,
  useZModal,
} from '@zignaly-open/ui';
import { ZigDotsVerticalIcon } from '@zignaly-open/ui/icons';
import {
  useBanMutation,
  useDisable2FAMutation,
  useUnbanMutation,
  useUsersQuery,
} from '../../apis/users/api';
import { useTranslation } from 'react-i18next';
import {
  UserAccessLevel,
  UserData,
  UserFilterType,
} from '../../apis/users/types';
import { ValueOrDash } from '../TableUtils/ValueOrDash';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import type { ZigTableQueryRef } from '@zignaly-open/ui';
import { isEqual as _isEqual } from 'lodash-es';
import {
  useAccessLevelOptions,
  useKYCStatusOptions,
  useSubscriptionExpiryOptions,
  useSubscriptionOptions,
} from './use';
import FilterButtons from '../TableUtils/FilterButtons';
import TableWrap, { TableFilterWrap } from '../TableUtils/TableWrap';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { format } from 'date-fns';
import { useCurrentlyManagedWl } from '../Config/use';
import { useWlConfigQuery } from '../../apis/config/api';
import EditEmailModal from './components/EditEmail';

const initialFilter: UserFilterType = {
  id: '',
  email: '',
  kycStatus: '',
  subscription: '',
  subscriptionExpired: '',
  access: '',
};

export default function Users() {
  const { t } = useTranslation(['users', 'common']);
  const [filters, setFilters] = useState<UserFilterType>(initialFilter);
  const wl = useCurrentlyManagedWl();
  const { data: config } = useWlConfigQuery(wl);
  const [errorInputId, setErrorInputId] = useState<string>('');

  const [filtersSubmitted, setFiltersSubmitted] =
    useState<UserFilterType>(filters);

  const { showModal } = useZModal();
  const [banUser] = useBanMutation();
  const [unbanUser] = useUnbanMutation();
  const [disable2fa] = useDisable2FAMutation();
  const theme = useTheme();
  const subscriptionsEnabled = !!config.settings.subscriptions;
  const accessLevelOptions = useAccessLevelOptions(subscriptionsEnabled);
  const kycStatusOptions = useKYCStatusOptions();
  const subscriptionOptions = useSubscriptionOptions();
  const subscriptionExpiryOptions = useSubscriptionExpiryOptions();
  const askConfirm = useZTypeWordConfirm();
  const ref = useRef<ZigTableQueryRef>();
  const columnHelper = createColumnHelper<UserData>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('userId', {
        header: t('table.userId'),
        cell: ({ getValue }) => (
          <ZigTypography variant={'body2'}>{getValue()}</ZigTypography>
        ),
      }),
      columnHelper.accessor('email', {
        header: t('table.email'),
        cell: ({ getValue }) => <ZigTypography>{getValue()}</ZigTypography>,
      }),
      columnHelper.accessor('accessLevel', {
        enableSorting: false,
        header: t('table.accessLevel'),
        cell: ({ getValue }) => (
          <ValueOrDash>
            {UserAccessLevel[getValue()]
              ? t('accessLevels.' + UserAccessLevel[getValue()])
              : getValue()}
          </ValueOrDash>
        ),
      }),
      columnHelper.accessor('kyc', {
        enableSorting: false,
        header: () => (
          <>
            {t('table.kycLevels-user')}
            <br />
            {t('table.kycLevels-trader')}
          </>
        ),
        cell: ({ getValue, row }) => (
          <ValueOrDash>
            {getValue()?.length && (
              <>
                {getValue().map(({ status, reason }) => (
                  <ZigTypography
                    variant={'body1'}
                    component={'div'}
                    key={'kyc' + row.original.userId}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {status}{' '}
                    {!!reason && (
                      <Tooltip title={reason}>
                        <HelpOutlineIcon
                          sx={{ ml: 0.5, height: 16, width: 16 }}
                        />
                      </Tooltip>
                    )}
                  </ZigTypography>
                ))}
              </>
            )}
          </ValueOrDash>
        ),
      }),
      ...(subscriptionsEnabled
        ? [
            columnHelper.accessor('subscription', {
              enableSorting: false,
              header: t('table.subscriptionLevel'),
              cell: ({ getValue }) => {
                const expired =
                  getValue()?.expirationDate < new Date().toISOString();
                const expiryDate =
                  getValue()?.expirationDate &&
                  new Date(getValue().expirationDate);
                return (
                  <ValueOrDash>
                    {getValue() && (
                      <>
                        <ZigTypography>
                          {getValue().plan}
                          &mdash;{' '}
                          <ZigTypography color={!expired ? 'green' : 'red'}>
                            {t(expired ? 'table.expired' : 'table.active')}
                          </ZigTypography>
                        </ZigTypography>

                        <Tooltip title={format(expiryDate, 'PP p')}>
                          <ZigTypography variant={'caption'} component={'div'}>
                            {t(
                              expired
                                ? 'table.expired-on-x'
                                : 'table.active-until-x',
                              {
                                date: format(expiryDate, 'PP'),
                              },
                            )}
                          </ZigTypography>
                        </Tooltip>
                      </>
                    )}
                  </ValueOrDash>
                );
              },
            }),
          ]
        : []),
      columnHelper.accessor('2faEnabled', {
        enableSorting: false,
        header: t('table.2faEnabled'),
        cell: ({ getValue }) => (
          <ZigTypography color={getValue() ? 'green' : 'red'}>
            {getValue() ? t('common:on') : t('common:off')}
          </ZigTypography>
        ),
      }),
      columnHelper.accessor((v) => v.accessLevel === -100, {
        enableSorting: false,
        id: 'isBanned',
        header: t('table.isBanned'),

        cell: ({ getValue }) => (
          <ZigTypography color={!getValue() ? 'green' : 'red'}>
            {getValue() ? t('common:yes') : t('common:no')}
          </ZigTypography>
        ),
      }),
      columnHelper.accessor(({ userId }) => userId, {
        header: '',
        id: 'actions',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const isBanned = original.accessLevel === -100;
          return (
            <ZigMenu
              component={() => (
                <IconButton sx={{ mr: '-4px' }}>
                  <ZigDotsVerticalIcon
                    color={theme.palette.neutral200}
                    height={16}
                    width={16}
                  />
                </IconButton>
              )}
              options={[
                {
                  label: t(isBanned ? 'actions.unban' : 'actions.ban'),
                  onClick: () =>
                    askConfirm({
                      description: t(
                        `actions.${isBanned ? 'unban' : 'ban'}-confirm`,
                        {
                          email: original.email,
                        },
                      ),
                      title: t(
                        isBanned ? 'actions.unban-user' : 'actions.ban-user',
                      ),
                      safeWord: t(isBanned ? 'actions.unban' : 'actions.ban'),
                      yesLabel: isBanned
                        ? t('actions.unban')
                        : t('actions.ban'),
                      yesAction: async () => {
                        await (
                          isBanned
                            ? unbanUser({ userId: original.userId })
                            : banUser({ userId: original.userId })
                        ).unwrap();
                        ref.current?.refetch();
                      },
                    }),
                },
                {
                  label: t('actions.edit-email'),
                  onClick: () =>
                    showModal(EditEmailModal, {
                      user: original,
                      refetch: () => ref.current?.refetch(),
                    }),
                },
              ].concat(
                original['2faEnabled']
                  ? {
                      label: t('actions.disable2FA'),
                      onClick: () =>
                        askConfirm({
                          title: t('actions.disable2FA'),
                          safeWord: t('actions.disable2FA-short'),
                          description: t(`actions.disable2FA-confirm`, {
                            email: original.email,
                          }),
                          yesLabel: t('actions.disable2FA'),
                          yesAction: async () => {
                            await disable2fa({
                              userId: original.userId,
                            }).unwrap();
                            ref.current?.refetch();
                          },
                        }),
                    }
                  : [],
              )}
            />
          );
        },
        enableColumnFilter: false,
      }),
    ];
  }, [filters, ref]);

  return (
    <PageContainer
      style={{
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 50px',
      }}
    >
      <ZigTypography sx={{ mb: 4 }} variant={'h1'}>
        {t('navigation.users')}
      </ZigTypography>

      <TableFilterWrap>
        <ZigInput
          size={'small'}
          error={errorInputId}
          label={t('table.userId')}
          placeholder={t('table.userId')}
          value={filters.id}
          onChange={(e) => {
            setErrorInputId('');
            setFilters((old) => ({ ...old, id: e.target.value }));
          }}
        />

        <ZigInput
          size={'small'}
          label={t('table.email')}
          placeholder={t('table.email')}
          value={filters.email}
          onChange={(e) =>
            setFilters((old) => ({ ...old, email: e.target.value }))
          }
        />

        <ZigSelect
          small
          label={t('table.accessLevel')}
          value={filters.access}
          onChange={(access) =>
            setFilters((old) => ({ ...old, access: access as string }))
          }
          options={accessLevelOptions}
        />

        <ZigSelect
          small
          label={t('table.kycLevels')}
          value={filters.kycStatus}
          onChange={(access) =>
            setFilters((old) => ({ ...old, kycStatus: access as string }))
          }
          options={kycStatusOptions}
        />

        {subscriptionsEnabled && (
          <ZigSelect
            small
            label={t('table.has-sub-filter')}
            value={filters.subscription}
            onChange={(v) =>
              setFilters((old) => ({
                ...old,
                subscription: v as string,
              }))
            }
            options={subscriptionOptions}
          />
        )}

        {subscriptionsEnabled && (
          <ZigSelect
            small
            label={t('table.sub-expiry-filter')}
            value={filters.subscriptionExpired}
            onChange={(v) =>
              setFilters((old) => ({
                ...old,
                subscriptionExpired: v as string,
              }))
            }
            options={subscriptionExpiryOptions}
          />
        )}

        <FilterButtons
          onClick={() => {
            if (!filters.id.match(/^[0-9a-fA-F]{24}$/) && filters.id !== '') {
              setErrorInputId(t('error.invalid-value'));
              return;
            }
            if (_isEqual(filters, filtersSubmitted)) ref.current?.refetch();
            else setFiltersSubmitted(filters);
          }}
          onClear={() => {
            setFilters({ ...initialFilter });
            setFiltersSubmitted({ ...initialFilter });
          }}
        />
      </TableFilterWrap>

      <TableWrap>
        <ZigTable
          ref={ref}
          initialState={{
            sorting: [
              {
                id: 'userId',
                desc: true,
              },
            ],
          }}
          queryExtraParams={filtersSubmitted}
          columns={columns}
          useQuery={useUsersQuery}
          enableSortingRemoval={false}
          emptyMessage={t('no-data')}
        />
      </TableWrap>
    </PageContainer>
  );
}
