export type TransfersState = {};

export type WithdrawalData = {
  id: string;
  userId: string;
  createdAt: string | null;
  amount: number;
  currency: string;
  status: string;
  email: string;
  title: string;
  transactionId: string;
  error: string | null;
  riskLevel: string | null;
  exposureType: string;
  nullable: true | null;
};

export type DepositData = {
  amount: number;
  coin: string;
  createdAt: string;
  email: string;
  id: string;
  userId: string;

  exposureType: string | null;
  riskLevel: string | null;
  status: string | null;
  transactionId: string;
};

export type TransferFilterType = {
  userId?: string;
  amount?: string;
  operator?: 'gte' | 'lte' | 'gt' | 'lt' | 'eq';
  status?: string;
};

export type TransferActionPayloadType = {
  id: string;
};

export enum DepositStatuses {
  STATUS_COMPLETED = 'completed',
  STATUS_FAILED = 'failed',
}

export enum WithdrawalStatuses {
  STATUS_COMPLETED = 'completed',
  STATUS_FAILED = 'failed',
}
