import { ZigTheme } from "../types";

const dark: ZigTheme = {
  mode: "dark",
  fontFamily: ["Avenir Next", "Roboto", "Helvetica", "Arial", "sans-serif"],
  // having this key is important
  // without it overriding would not work
  fontFamilyH1H6: undefined,
  palette: {
    neutral900: "#06061A",
    neutral800: "#12152c",
    neutral750: "#171b30",
    neutral700: "#252339",
    neutral600: "#35334A",
    neutral500: "#4A4958",
    neutral400: "#706F82",
    neutral300: "#89899A",
    neutral200: "#A9A9BA",
    neutral175: "#889AD0", // TODO: make semantic
    neutral150: "#BAC1E4",
    neutral100: "#C1C1C8",
    neutral000: "#E1E9F0",
    secondary: "#515AAC",
    highlighted: "#7682F7",
    red: "#CC3993",
    criticalRed: "#CC394E",
    green: "#26c496",
    links: "#26C4C1",
    yellow: "#ffa726",
    contrasting: "#F3F4F6",
    lightGrey: "#9ca3af",
    paleBlue: "#999fe1",
  },

  chart: {
    red: "#CC3993",
    green: "#039179",
    greenGradient: ["rgba(18, 33, 59, 0.52)", "rgba(33, 81, 78, 0.69)"],
    greenMiniGradient: ["rgba(17, 27, 47, 0)", "rgba(22, 41, 67, 0.5)", "rgba(39, 110, 107, 1)"],
    redGradient: ["rgba(31, 18, 59, 0.52)", "rgba(74, 33, 81, 0.69)"],
    redMiniGradient: ["rgba(18, 20, 39, 0)", "rgba(21, 21, 57, 0.5)", "rgba(86, 36, 108, 1)"],
  },
  backgrounds: {
    zscoreGradientProfits: "linear-gradient(to right, #0e272e, #2acbfe 47%, #0ceaae 88%)",
    zscoreGradientRisk: "linear-gradient(to right, #210f45, #5533cf, #50a9ee)",
    zscoreGradientService: "linear-gradient(to right, #1f1030, #6d0b82 42%, #dd4393 76%, #f24196)",
    zscoreGradientBalanced: "linear-gradient(to right, #10341d, #46995c 42%, #76dd88 76%, #88c489)",
    zscore: "linear-gradient(to top, #3e3177, #263e6f)",
    zscoreProgressBar: "#242842",
    tableHeader: "#06061A",
    toastError: "#231630",
    toastSuccess: "#122431",
    marketplaceCarousel: `radial-gradient(
      circle at center,
      #2c418563 0%,
      #15193663 55%,
      #20202563 100%
    )
    #0b0b23`,
    inviteTableHighlight: `radial-gradient(
    circle at center,
    rgba(16, 13, 70, 0.4) 0%,
    rgba(16, 25, 70, 0.4) 27%,
    transparent 51%
  )`,
    richTextEditor: `linear-gradient(
      90deg,
      rgb(16 18 37) 0%,
      rgb(16 18 37) 35%,
      rgb(16 18 37) 100%
    )`,
    tableRow: "transparent",
    body: `url(https://imagedelivery.net/qNg0fDlw9b2DximxcnB4cA/4048a0ac-9c9d-4298-4146-fb7b1524d900/public) #070819`,
    header: "linear-gradient(269.14deg, #080810 0%, #11122b 100%)",
    neutralBg: "#101225",
    buttonPrimary: "linear-gradient(289.8deg, #149CAD 0%, #4540C1 100%)",
  },
  boxShadows: {
    header: "0 4px 8px rgba(0, 0, 0, 0.5)",
    zigMenu: "0 4px 6px -2px #00000061",
  },
  imageColorOverride: "",
};

export default dark;
